/**
 * Copyright (C) 2022 Johnny Robeson <johnny@localmomentum.net>
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */
const apiRoot = '/api';

export const config = {
  apiRoot: apiRoot,
  gameEventUrl: `${apiRoot}/games/events`,
  activeGameUrl: `${apiRoot}/games/active`,
};
