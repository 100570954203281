/**
 * Copyright (C) 2022 Johnny Robeson <johnny@localmomentum.net>
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SSEProvider } from 'react-hooks-sse';

import { config } from './config';
import { FetchEventSourceSource } from './FetchEventSourceSource';
import ActiveGamePage from './ActiveGamePage';

import './App.css';

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <SSEProvider source={() => new FetchEventSourceSource(config.gameEventUrl)}>
        <ActiveGamePage />
      </SSEProvider>
    </QueryClientProvider>
  );
}
