/**
 * Copyright (C) 2022 Johnny Robeson <johnny@localmomentum.net>
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import './index.css';

const rootElement = document.getElementById('root')!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
}
