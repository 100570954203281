/**
 * Copyright (C) 2022 Johnny Robeson <johnny@localmomentum.net>
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */
import { useSSE } from 'react-hooks-sse';

import { Game } from './interfaces';

import blueTeamImage from './assets/gelly-ball-logo-blue-team.svg';
import redTeamImage from './assets/gelly-ball-logo-red-team.svg';
import greenTeamImage from './assets/gelly-ball-logo-green-team.svg';

import styles from './SimpleScoreboard.module.css';

interface TeamScoreAndHit {
  score: number;
  hits: number;
}
interface TeamScoresAndHits {
  teamId: number;
  scoreAndHits: TeamScoreAndHit;
}

interface SimpleScoreboardProps {
  game: Game;
}
export default function SimpleScoreboard({ game }: SimpleScoreboardProps) {
  const state = useSSE<TeamScoresAndHits>('game.team_scores_and_hits', null as unknown as TeamScoresAndHits);

  if (state && state.scoreAndHits) {
    const teamIndex = game.teams.findIndex((team) => team.id === state.teamId);
    // FIXME: the teamIndex should always be from the current game!
    if (Object.hasOwn(game.teams, teamIndex)) {
      game.teams[teamIndex].hits = state.scoreAndHits.hits;
      game.teams[teamIndex].score = state.scoreAndHits.score;
    }
  }
  const teamImages = { blue: blueTeamImage, red: redTeamImage, green: greenTeamImage };

  return (
    <div className={styles.scoreboard}>
      {game.teams.map((team) => (
        <div key={team.id} className={styles.team}>
          {team.color && (
            <img
              key={team.color}
              className={styles.teamLogo}
              src={(teamImages as Record<string, string>)[team.color]}
              alt={`${team.color} team logo`}
            />
          )}
          <div className={styles.teamScoreBox}>
            <h2>{team.displayName} Score</h2>
            <div className={styles.teamScore}>{team.score}</div>
            <div className={styles.teamHitBox}>
              <h2>Hits</h2>
              <div key={team.id + game.id} className={styles.teamHits}>
                {team.hits}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
