/**
 * Copyright (C) 2022 Johnny Robeson <johnny@localmomentum.net>
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */
import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useSSE } from 'react-hooks-sse';
import Countdown from 'react-countdown';

import { Game } from './interfaces';
import SimpleScoreboard from './SimpleScoreboard';

import styles from './ActiveGamePage.module.css';

export default function ActiveGamePage() {
  const game = useSSE<Game>('game.start', null as unknown as Game, {
    parser: (data: any) => {
      console.log('data', data);
      return JSON.parse(data);
    },
  });

  return (
    <div>
      <main>
        {game ? (
          <>
            <h1 className={styles.timerContainer}>
              Game Time: <Countdown daysInHours={true} key={game.startedAt} date={game.endsAt ?? undefined} />
            </h1>
            <SimpleScoreboard game={game} />
          </>
        ) : (
          <h2 style={{ textAlign: 'center' }}>No Active game</h2>
        )}
      </main>
    </div>
  );
}
