/**
 * Copyright 2022 Johnny Robeson <johnny@localmomentum.net>.
 * SPDX-License-Identifier: 	AGPL-3.0-or-later
 */
import { Listener } from 'react-hooks-sse';
import { fetchEventSource, EventStreamContentType } from '@microsoft/fetch-event-source';

export type EventListenerAndObject = EventListener | EventListenerObject | null;

interface MessageEventLike extends Event {
  data?: string;
}

interface ExtendedListener extends Listener {
  (evt: MessageEventLike): void;
}

class RetriableError extends Error {}
class FatalError extends Error {}

export class FetchEventSourceSource {
  private eventTarget: EventTarget;
  private abortController: AbortController | null = null;

  constructor(private readonly eventSourceUrl: string) {
    this.eventTarget = new EventTarget();
    this.abortController = new AbortController();
    this.init();
  }
  init() {
    const that = this; // eslint-disable-line @typescript-eslint/no-this-alias
    fetchEventSource(this.eventSourceUrl, {
      async onopen(res: Response) {
        console.log('onopen');
        if (res.ok && res.headers.get('content-type') === EventStreamContentType) {
          console.log('Connected to event source');
          return;
        } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
          // client-side errors are usually non-retriable:
          throw new FatalError();
        } else {
          throw new RetriableError();
        }
      },
      onmessage(msg) {
        if (msg.event === '') {
          return;
        }
        // if the server emits an error message, throw an exception
        // so it gets handled by the onerror callback below:
        if (msg.event === 'error') {
          throw new FatalError(msg.data);
        }
        that.emit(msg.event, msg.data);
      },
      onclose() {
        console.log('onclose retriable');
        throw new RetriableError();
      },
      onerror(err) {
        if (err instanceof FatalError) {
          throw err;
        }
        console.log('maybe retry');
        // automatically retry on retriable errors
      },
      openWhenHidden: true,
      signal: that.abortController?.signal,
    })
      .then(() => console.log('sucessfully inited eventsource'))
      .catch((err) => {
        console.log(`EVENT SOURCE ERROR: ${JSON.stringify(err, null, 2)}`);
      });
  }

  emit(name: string, data: string) {
    console.log(`EVENT NAME EMITTED: ${name}`);
    this.eventTarget.dispatchEvent(new MessageEvent(name, { data }));
  }

  addEventListener(name: string, listener: ExtendedListener) {
    this.eventTarget.addEventListener(name, listener);
  }

  removeEventListener(name: string, listener: ExtendedListener) {
    this.eventTarget.removeEventListener(name, listener);
  }

  close() {
    return;
  }
}
